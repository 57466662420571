@import '~@angular/material/prebuilt-themes/indigo-pink.css';
/* * * * * * * * * * * Variables * * * * * * * * * * */
$primary-color-light: #f7f6f6;
$primary-color: #00569c;
$secondary-color: #dddddd;
$nav-header-height: 54px;
$nav-header-colour: #fff;
$container-background-color: #f7f7f7;
$main-text-police: Roboto, RobotoFallback, 'Noto Kufi Arabic', Helvetica, Arial,
  sans-serif;
$container-secondary-background-color: #ffffff;
$toolbar-height: 55px;
$toolbar-secondary-height: 45px;

:root {
  --side-nav-background-color: #{$primary-color-light};
  --side-nav-header-color: #{$secondary-color};
  --side-nav-header-height: #{$nav-header-height};
  --side-nav-text-color: #{$nav-header-colour};
  --nav-header-colour: $nav-header-colour;
  --primary-color: #{$primary-color};
  --primary-color-light: #{$primary-color-light};
  --secondary-color: #{$secondary-color};
  --container-secondary-background-color: #{$container-secondary-background-color};
  --nav-toolbar-height: 60px;
  --nav-toolbar-text-color: #{$primary-color};
  --side-nav-width: 60px;
  --toolbar-height: #{$toolbar-height};
  --toolbar-secondary-height: #{$toolbar-secondary-height};
  --app-name-text-size: 32px;
  --app-text-police: $main-text-police;
  --container-title-color: #{$primary-color};
}

/* * * * * * * * * * * App Style * * * * * * * * * * */
html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
  background-color: $container-background-color;
}

.content-style-class {
  padding: 10px;
  background-color: $container-background-color;
  border-top-style: solid;
  border-top-color: #dddddd;
  height: calc(100vh - 55px) !important;
}

.content-block {
  margin: 0 20px 20px 20px;
  height: calc(100vh - 149px);
  max-height: 100%;

  .layout-body {
    padding: 20px;
  }

  .screen-x-small & {
    margin-left: 20px;
    margin-right: 20px;
  }

  .dx-card {
    box-shadow: 0px 0px 0px !important;
  }
}

.add-button-style {
  width: fit-content;
  margin-bottom: 10px;

  .dx-button-has-text .dx-button-content {
    padding: 15px;
    color: white !important;
  }

  .dx-button-mode-contained {
    background-color: var(--primary-color) !important;
    border-radius: 30px;
  }

  .dx-icon.dx-icon-add::before {
    color: white !important;
  }
}

.title-style {
  width: 321px;
  height: 30px;
  color: $primary-color;
  font-weight: bold;
  text-align: left;
  margin-top: 0px;
}

.dx-scrollable-wrapper>.dx-scrollable-container>.dx-scrollable-content {
  &>.dx-scrollview-content {
    display: flex;
    flex-direction: column;
  }
}

::ng-deep .dx-datagrid-group-panel .dx-group-panel-item {
  color: rgb(205, 14, 14) !important;
  font-weight: 700 !important;
}

.dx-datagrid-nowrap .dx-header-row>td>.dx-datagrid-text-content {
  white-space: nowrap;
  font-weight: 700 !important;
  color: #333 !important;
}

.dx-datagrid-text-content {
  color: #333;
  font-weight: 700 !important;
}

.dx-datagrid .dx-datagrid-header-panel .dx-group-panel-item,
.dx-datagrid .dx-datagrid-header-panel .dx-header-filter {
  border-radius: 4px;
}

.dx-datagrid-borders>.dx-datagrid-filter-panel {
  border-radius: 0px 0px 4px 4px;
}

.dx-datagrid-borders>.dx-datagrid-headers,
.dx-datagrid-borders>.dx-datagrid-pager {
  border-radius: 4px 4px 0px 0px;
}

.dx-datagrid-rowsview .dx-row {
  color: #595959;
}

.dx-fa-button-icon {
  background-color: var(--primary-color) !important;
}

.favorite-inactive {
  content: url(assets/images/favorite.svg) !important;
}

.favorite-active {
  content: url(assets/images/favorite_active.svg) !important;
}

.grid-group-by-style {
  .dx-icon-trash::before {
    content: url(assets/images/delete.svg);
  }

  .dx-icon-edit::before {
    content: url(assets/images/edit.svg) !important;
  }

  .dx-icon-column-chooser::before {
    content: url(assets/images/columns.svg) !important;
  }

  .dx-icon-export-to::before {
    content: url(assets/images/export.svg) !important;
  }

  .dx-icon-parentfolder::before {
    content: url(assets/images/edit.svg) !important;
  }

  .dx-icon-textdocument::before {
    content: url(assets/images/report.svg) !important;
  }

  .dx-datebox-calendar .dx-dropdowneditor-icon::before {
    content: url(assets/images/calendar-grid.svg) !important;
  }

  .dx-icon-folder::before {
    content: url(assets/images/n_right.svg) !important;
  }

  .dx-datagrid-text-content {
    font-weight: 700 !important;
  }

  .dx-datagrid-group-panel .dx-group-panel-item {
    font-weight: 700 !important;
    color: #959595 !important;
  }

  .dx-datagrid-filter-panel-text {
    font-weight: 700;
    color: var(--primary-color) !important;
  }

  .dx-icon-filter::before {
    color: var(--primary-color) !important;
  }

  .dx-datagrid .dx-header-filter::before {
    content: url(assets/images/filter.svg) !important;
  }

  .dx-datagrid .dx-column-indicators .dx-header-filter.dx-header-filter::after {
    content: url(assets/images/filter.svg) !important;
    top: 6px !important;
    right: 4px;
    left: -7px;
  }

  .dx-sort {
    padding-right: 15px;
  }

  .dx-group-row {
    color: #595959 !important;
  }
}

.drop-down-style .dx-dropdowneditor-icon::before {
  content: url(assets/images/ddarrow.svg) !important;
}

.date-box-style .dx-dropdowneditor-icon::before {
  content: url(assets/images/calendar.svg) !important;
}

mat-icon.mat-icon.notranslate.company-select-icone.material-icons.mat-icon-no-color::before {
  content: url(assets/images/ddarrow.svg) !important;
}

.responsive-paddings {
  height: 100%;
  padding: 10px;
}

.container {
  background-color: #ececec;
}

/* Drawer Overlay (Open/Close) */
.dx-overlay-content {
  position: absolute;
  pointer-events: auto;
  z-index: 1000;
  outline: 0;
  overflow: hidden;
}

/* * * * * * * * * * * Theme * * * * * * * * * * */
/* Widget */
.dx-widget {
  font-family: var(--app-text-police);
}

/* Card */
.dx-card {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  background-color: #fff;
}

/* Toolbar */
.dx-toolbar-items-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.dx-toolbar-item {
  display: table-cell;
  padding: 0 5px;
  vertical-align: middle;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.dx-toolbar-after,
.dx-toolbar-before,
.dx-toolbar-center {
  top: 0;
  display: table;
  height: 100%;
}

.dx-toolbar-after,
.dx-toolbar-before {
  position: absolute;
}

::ng-deep .dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 40px;

  :host-context(.screen-x-small) & {
    padding: 0 20px;
  }
}

.dx-toolbar-before {
  left: 0;
}

.dx-toolbar-after {
  right: 0;
}

.dx-toolbar .dx-toolbar-items-container {
  overflow: visible;
}

.dx-toolbar .dx-toolbar-item:last-child {
  padding: 0;
}

.dx-toolbar .dx-toolbar-label {
  font-size: 20px;
  font-weight: 500;
}

/* Drawer */
.dx-drawer {
  height: 100%;
  width: 100%;
}

.dx-drawer-panel-content {
  box-shadow: 3px 0px 10px #80808042 !important;
}

.dx-drawer-wrapper {
  position: relative;
  overflow: hidden;
  height: 100%;
}

::ng-deep .dx-drawer-content {
  display: flex !important;
}

.dx-drawer-shrink .dx-drawer-panel-content {
  z-index: 1;
  position: relative;
  overflow: hidden;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  height: 100%;
}

.dx-drawer-shrink .dx-drawer-wrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.dx-drawer-shrink .dx-drawer-content {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

::ng-deep .dx-drawer-content {
  display: flex !important;
}

.dx-drawer-shrink.dx-drawer-bottom .dx-drawer-wrapper,
.dx-drawer-shrink.dx-drawer-top .dx-drawer-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.dx-drawer-overlap .dx-drawer-panel-content {
  overflow: visible;
}

.dx-drawer-overlap .dx-drawer-panel-content.dx-overlay-wrapper {
  right: auto;
  left: auto;
}

.dx-drawer-overlap.dx-drawer-left .dx-drawer-wrapper,
.dx-drawer-overlap.dx-drawer-right .dx-drawer-wrapper {
  overflow-y: visible;
}

.dx-drawer-overlap.dx-drawer-bottom .dx-drawer-wrapper,
.dx-drawer-overlap.dx-drawer-top .dx-drawer-wrapper {
  overflow-x: visible;
}

.dx-drawer-overlap.dx-drawer-right.dx-drawer-expand>.dx-drawer-wrapper>.dx-overlay>.dx-overlay-wrapper>.dx-overlay-content,
.dx-drawer-overlap.dx-drawer-right.dx-drawer-slide>.dx-drawer-wrapper>.dx-overlay>.dx-overlay-wrapper>.dx-overlay-content {
  right: 0;
}

.dx-button-has-text .dx-button-content {
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 6px 16px;
}

/* ContextMenu */
.dx-context-menu.dx-overlay-content {
  overflow: inherit;
  position: absolute;
}

.dx-context-menu .dx-menu-item .dx-menu-item-content {
  padding: 5px 10px;
  display: flex;
}

.dx-context-menu .dx-menu-item.dx-menu-item-has-text.dx-menu-item-has-icon .dx-icon {
  margin-right: 15px;
}

.dx-context-menu .dx-menu-items-container {
  padding: 8px 0;
  margin: 0px;
}

.dx-context-menu .dx-submenu {
  background-color: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);
  width: max-content;
}

.dx-popup-wrapper>.dx-overlay-content {
  box-shadow: 0 1px 5px #dddddd !important;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
  height: 2px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(191, 191, 191, 0.7) !important;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  -moz-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

.warning-msg {
  color: rgb(247, 10, 10);
  font-weight: 500;
  float: right;
  font-size: 14px;
  line-height: 18px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(191, 191, 191, 0.7) !important;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.05);
}

.dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active,
.dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active.dx-scrollable-scroll,
.dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-state-hover,
.dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-state-hover.dx-scrollable-scroll {
  width: 10px !important;
}

.mat-expansion-panel-body {
  background-color: white !important;
  padding: 0 24px 0px !important;
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: #00569c;
}

.dx-popup-title {
  color: white !important;
  background-color: var(--primary-color) !important;

  p {
    font-size: 20px !important;
    margin: 14px;
  }
}

.close-popup-btn {
  cursor: pointer !important;
}

.dx-overlay-wrapper.dx-datagrid-column-chooser.dx-popup-wrapper.dx-datagrid-column-chooser-mode-drag {
  .dx-icon.dx-icon-close {
    color: white;
  }

  .dx-popup-content.dx-treeview-with-search.dx-empty-collection.dx-widget.dx-collection.dx-treeview.dx-datagrid-column-chooser-list {
    padding: 20px 20px 20px;
  }
}

.dx-datagrid-column-chooser.dx-datagrid-column-chooser-mode-drag .dx-popup-content .dx-treeview-search {
  margin-top: 7px !important;
}